import React from 'react'
import {Link} from 'gatsby'
import LS from '../components/LocalStorage';
import { StaticQuery, graphql } from 'gatsby';

const NavbarQuery = graphql`
    query{
        markdownRemark(frontmatter: { path: { eq: "/" } }) {
            frontmatter{
                 navbar{
                    why_share 
                    about 
                    shop 
                    contact 
                    cart 
                 }
            }       
        }
    }   
`

const Header = ({content}) => {

    const number_of_products = LS.getItems('products') && LS.getItems('products').length;
    return(
        <header>
            <nav className="site-navbar">
                <div className="container flexbox-space-between">
                    <Link to="/" className="site-brand">
                        <img itemProp="image" src="/images/logo.svg" alt="Shoperone Logo"/>
                    </Link>
                    <ul className="menu left-side">
                        <li>
                            <Link activeClassName="active" to="/#why-share">
                                {content.why_share}
                            </Link>
                        </li>
                        <li>
                            <Link activeClassName="active" to="/about">
                                {content.about}
                            </Link>
                        </li>
                        <li>
                            <Link activeClassName="active" to="/#shop">
                                {content.shop}
                            </Link>
                        </li>                        
                        <li className="contact">
                            <Link activeClassName="active" to="/contact">
                                {content.contact}
                            </Link>
                        </li>
                                               
                        {number_of_products>0?
                            <li>
                                <Link activeClassName="active" to="/cart" className="cart-link">
                                    {content.cart}
                                    <span className="cart-count">{number_of_products}</span>
                                </Link>
                            </li>
                        :''}
                    </ul>
                </div>
            </nav>
        </header>
    )
}

const Navbar=()=>(
    <StaticQuery
      query={NavbarQuery}
      render={(data)=>{
         const content = data.markdownRemark.frontmatter.navbar;
         return(
            <Header content={content}/>
         )
      }}
    />
);

export default Navbar;