import React from 'react';
import LS from '../LocalStorage';

class CookieComponent extends React.Component {
  state = {
    consent_status: [{ accepted: true }]
  }

  componentDidMount(){
    let consent_status = LS.getItems('cookie_consent_status')
    if (!consent_status) {
      this.setState({ consent_status: [{ accepted: false }]});
    }
  }

  acceptCookies = () => {
    LS.addItem('cookie_consent_status', { accepted: true });
    this.setState({ consent_status: [{ accepted: true }] });
  }

  render() {
    if (this.state.consent_status && this.state.consent_status[0].accepted === true) {
      return null;
    }
    return (
      <div className="cookie-message-container">
        <div className="container">
          <div className="flex-content">
            <p>{this.props.content.cookie_explanation1}</p>
            <p>{this.props.content.cookie_explanation2}</p>
            <p>{this.props.content.cookie_explanation3}</p>
            <button type="button" className="cta" onClick={this.acceptCookies}>{this.props.content.button_text}</button>
          </div>
        </div>
      </div>
    )
  }
}

export default CookieComponent;