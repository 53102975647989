import React from 'react'
import Helmet from 'react-helmet'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import 'antd/dist/antd.min.css';
import '../css/main.css';
import '../css/normalize.css';

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet>
        <title>Gemensak</title>
        <link rel="icon" href="/images/favicon.png" type="image/png" />
        <meta name="google-site-verification" content="8tvLUoKNRfsmwVY2oFzd5SVe_badBTNSQ1pCPdisy4c" />
    </Helmet>
    <Navbar />
    <main className="site-content">
        {children}
    </main>
    <Footer />
  </div>
)

export default TemplateWrapper
