import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import CookieComponent from './common_view_components/CookieComponent';
const FooterQuery = graphql`
    query{
        markdownRemark(frontmatter: { path: { eq: "/" } }) {
            frontmatter{
                 footer{
                    copyright
                    contact
                    terms_of_use 
                    privacy_policy
                    cookie_explanation1
                    cookie_explanation2
                    cookie_explanation3
                    button_text
                 }
            }       
        }
    }   
`

const FooterComponent = ({ content }) => (
  <footer>
    <CookieComponent content={content} />

    <div className="container flexbox-space-between">
      <div className="copyright">
        &copy; {content.copyright}
      </div>

      <div className="social-links">
        <div className="footer-nav">
          <a href="/contact">{content.contact}</a>
          <a href="/terms-of-use">{content.terms_of_use}</a>
          <a href="/privacy-policy">{content.privacy_policy}</a>
        </div>        
      </div>
    </div>
  </footer>
)

const Footer = () => (
  <StaticQuery
    query={FooterQuery}
    render={(data) => {
      const content = data.markdownRemark.frontmatter.footer;
      return (
        <FooterComponent content={content} />
      )
    }}
  />
);

export default Footer;

