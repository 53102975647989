import ls from 'local-storage';
import _ from 'lodash';

const addItem=(key, value)=>{
    let old_data = ls.get(key);
    if(old_data){
        ls.set(key, [...old_data, value]);
    }else{
        ls.set(key, [value])
    }
};

const getItems=(key, defaultValue=undefined)=>{
    return ls.get(key) ? ls.get(key) : defaultValue;
};

const removeItem=(key, value)=>{
    let stored_items = ls.get(key);
    if(stored_items){
        let product_index = _.findIndex(stored_items, value);
        stored_items.splice(product_index, 1);
        if(stored_items.length){
            ls.set(key, stored_items)
        }else{
            ls.remove(key)
        }
    }
};

const clearStorage=()=>{
    ls.clear();
}

const LS = {
    addItem,
    getItems,
    removeItem,
    clearStorage
};

export default LS;